import React from 'react';
import { capitalizeFirstLetter } from '../../../../common/helpers';
import { getDxfFileNameError, getLowerSw0Template, getUpperSw0Template } from '../../../../redux/reducers/bpp/wire_selection/wire_selection';
import { setSw0Arch } from '../../../../redux/actions/bpp/wire_selection/wire_selection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onDownload } from '../../../../common/functions';

const SwZeroArch = (props) => {
  const { swZeroArch, case_id, ws, upperSw0Template, lowerSw0Template, dxfFileNameError, current_rev, reworkInProgress, getReworkWireInfo } = props;
  const { setSw0Arch, onTemplateChange, onUploadClick, onRemoveClick } = props;
  const wireName = `${swZeroArch}_specialty`;

  const templates = [
    { label: 'Mini Tab', pn: '80010', checked: false, type: 'mini_tab' },
    { label: 'Opening Loop', pn: '80011', checked: false, type: 'opening_loop' },
    { label: 'Mini Tab and Opening Loop', pn: '80012', checked: false, type: 'mini_tab_opening_loop' },
    { label: 'Experimental', pn: '', checked: false, type: 'experimental' },
  ];

  const getWireInfo = () => {
    if (reworkInProgress) {
      return getReworkWireInfo(wireName);
    }
    return ws.filter((wire) => wire.wire_name === wireName);
  };

  const wireInfo = getWireInfo();
  const isWireSelected = wireInfo.length > 0;
  const selectedTemplate = !isWireSelected || wireInfo[0].selection === 'none' ? null : wireInfo[0].selection;
  const file = isWireSelected && wireInfo[0].case_file_manual__upload_data;

  const toggleArch = (e) => {
    const selectedArch = e.target.value;
    const toggledValue = !isWireSelected;
    const template = selectedArch === 'upper' ? upperSw0Template : lowerSw0Template;
    setSw0Arch(selectedArch, toggledValue, case_id, template);
  };

  return (
    <div className="sw0-item">
      <div className="sw0-arch">
        {!reworkInProgress && <input type="checkbox" value={swZeroArch} name="wire-selection-radio" checked={isWireSelected} onChange={toggleArch} />}
        {!(reworkInProgress && !isWireSelected) && (
          <>
            <label className="bold-text">{capitalizeFirstLetter(swZeroArch)}</label>{' '}
          </>
        )}
        {isWireSelected && (
          <>
            {file ? (
              <>
                (Uploaded){' '}
                <a className="viewable-text file-span" href={`/${file}`} download onClick={onDownload}>
                  <i className="fa fa-file-archive-o" />
                </a>{' '}
                <span data-original-title="Remove DXF File" data-toggle="tooltip">
                  <i
                    className="fa fa-trash"
                    onClick={() => {
                      onRemoveClick('Remove Manual Custom Wire', 'ws_manual', file);
                    }}
                  />
                </span>
              </>
            ) : (
              <span data-original-title="Upload DXF File" data-toggle="tooltip">
                <i
                  className="fa fa-upload"
                  onClick={() => {
                    onUploadClick(wireName, 'Manual Custom Wire', 'ws_dxf', false, current_rev);
                  }}
                />
              </span>
            )}
          </>
        )}
        {dxfFileNameError.wire_name === wireName && (
          <>
            {dxfFileNameError.case_id ? (
              <div className="dxf-error">Case ID Mismatch</div>
            ) : dxfFileNameError.format ? (
              <div className="dxf-error">Incorrect File Naming Convention</div>
            ) : null}
          </>
        )}
      </div>
      {isWireSelected ? (
        <div className="sw0-types">
          {templates.map((template, index) => {
            return (
              <div key={index}>
                <input
                  type="radio"
                  data-arch={swZeroArch}
                  value={template.type}
                  checked={selectedTemplate === template.type}
                  onChange={() => {
                    onTemplateChange(template.type, wireName);
                  }}
                />
                <label>{template.label}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dxfFileNameError: getDxfFileNameError(state),
    upperSw0Template: getUpperSw0Template(state),
    lowerSw0Template: getLowerSw0Template(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSw0Arch: setSw0Arch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SwZeroArch);
