/**
 * File:pr_submission.js - Component to submit progress record/clinical questionaire  on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './csr_submission.scss';
// External Libs
import Axios from 'axios';
import { cloneDeep, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Internal Components
import TreatmentData from '../../doctor/csr_submission/treatment_details.json';
import { TreatmentDataGen2 } from '../../doctor/csr_submission/treatment_details_gen2';
import CsrData from '../../doctor/csr_submission/csr.json';
// import BackButton from '../../components/button/back_button';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
// import NextButton from '../../components/button/next_button';
import NextButton from '../../components/button/bpp_next_button';
import BackButton from '../../components/button/bpp_back_button';
import NotFound from '../../doctor/404/not_found';
import Upload from './pr_upload';
import TreatmentDetails from './treatment_details';

import SubmitterLoader from './submitting_loader';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import ThumbnailUrlInterface from '../../components/thumbnail/thumbnail_url_interface';
// import { configLightBox } from '../../common/functions';
import { setTokenHeader, convertSex, textFieldLimited, configLightBox, removeEmoji } from '../../common/functions';
import { getDoctorAssignedNameFromCaseDetails } from '../../common/dso';
import { scrollToTop } from '../../common/scroll';
import { getTempLink } from '../../common/dropbox';
import EmptyFileErrorModal from '../../components/modal/empty_file';
import { ApiServiceInstance } from '../../common/api_service';

class CsrSubmission extends Component {
  constructor(props) {
    super(props);
    const treatment_data = this.props.gen_2 ? cloneDeep(TreatmentDataGen2) : cloneDeep(TreatmentData);
    const csr_data = cloneDeep(CsrData);
    this.state = {
      case_id: '',
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      patient_first_name: '',
      patient_last_name: '',
      title: 'Progress Records',
      step: 'pr_upload',
      mode: 'pr_submit',
      total_steps: '3',
      current_step: '1',
      buttons: <NextButton position={'next'} buttonClass={'bpp-submit-next'} description={'Parts'} onButtonClick={this.onButtonClick} />,
      warning: false,
      error: false,
      error_type: '',
      treatment_data: treatment_data,
      csr_data: csr_data,
      //Uploads
      fileUpload: [],
      //Upload in progress
      files_uploading: [],
      file_location: '',
      upload_date: '',
      file_removing: false,
      csr_requested: false,
      edit_comment: false,
      edit_status_commentedit_status_comment: '',
      status_comment: '',
      pr_exists: true,
      showEmptyFileModal: false,
    };

    this.goBack = this.goBack.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    let csr_data = cloneDeep(this.state.csr_data);
    let treatment_data = '';
    let mode = this.state.mode;
    let title = this.state.title;
    if (this.props.progress_record.csq_data) {
      treatment_data = JSON.parse(cloneDeep(this.props.progress_record.csq_data), (k, v) => (v === 'true' ? true : v === 'false' ? false : v));
      mode = 'pr_upload';
      title = 'Progress Records';
    }
    let csr_requested = false;
    if (this.props.type === 'CSR') {
      csr_requested = true;
    }
    const that = this;
    let case_id = this.props.case_id;
    let fileUpload = this.props.new_files;
    let step = this.state.step;
    let total_steps = this.state.total_steps;
    const status_comment = this.props.status_comment;
    ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        csr_data.case.case_id = res.data.base_id;
        treatment_data.case.case_id = res.data.base_id;
        csr_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
        csr_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
        csr_data.case.sex = convertSex(res.data.patient.sex);
        csr_data.case.dob = res.data.patient.dob;

        treatment_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
        treatment_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
        treatment_data.case.sex = convertSex(res.data.patient.sex);
        treatment_data.case.dob = res.data.patient.dob;

        this.setState({
          case_id: case_id,
          mode: mode,
          title: title,
          step: step,
          total_steps: total_steps,
          loading: false,
          patient_first_name: res.data.patient.first_name,
          patient_last_name: res.data.patient.last_name,
          address: res.data.cases[0].shipping_address,
          csr_data: csr_data,
          fileUpload: fileUpload,
          treatment_data: treatment_data,
          status_comment: status_comment,
          csr_requested: csr_requested,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  isProgressRecordDeleted = () => {
    setTokenHeader();
    let that = this;
    const case_id = this.props.case_id;
    const progress_id = this.props.progress_record.progress_id;
    return ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        const pr = res.data.progress_record.filter(function (q) {
          return q.progress_id === parseInt(progress_id);
        });
        let exists = true;
        if (pr.length === 0) {
          exists = false;
        }
        that.setState({
          pr_exists: exists,
        });
      })
      .catch(function (err) {
        console.log(err);
        return false;
      });
  };

  redirectUserToCaseDetail() {
    this.props.ReturnBack();
  }

  validateUpdates = (event) => {
    let different_upload = false;
    let different_csq = false;
    let orig_file_name = [];
    let new_file_name = [];
    this.props.progress_record.files.forEach(function (file) {
      orig_file_name.push(file.original_filename);
    });
    this.state.fileUpload.forEach(function (file) {
      new_file_name.push(file.original_filename);
    });
    if (orig_file_name.sort().join('') !== new_file_name.sort().join('')) {
      different_upload = true;
    }

    if (
      !isEqual(
        JSON.parse(cloneDeep(this.props.progress_record.csq_data), (k, v) => (v === 'true' ? true : v === 'false' ? false : v)),
        this.state.treatment_data
      )
    ) {
      different_csq = true;
    }

    const deleted_pr = this.state.pr_exists;

    if (!deleted_pr) {
      let warning = document.querySelector('#warning-submit');
      let warning_text = '<ul class="wizard-error-text"><li>Submission deleted.</li></ul>';
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Update Submission',
        });
      }

      return false;
    }

    if (different_upload || different_csq) {
      return true;
    } else {
      let warning = document.querySelector('#warning-submit');
      let warning_text = '<ul class="wizard-error-text"><li>No updates have been made.</li></ul>';
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Update Submission',
        });
      }
      return false;
    }
  };

  /**
   * Show Empty File Modal
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Hide Empty File Modal
   * @param {Hide } event
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };

  onSubmitButtonClick = (event) => {
    if (this.validateUpload() && this.validateUpdates() && this.state.status_comment && !this.state.edit_comment) {
      const case_id = this.state.case_id;
      let data = '';
      const files = JSON.stringify(this.state.fileUpload);
      const date_taken = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'pr_date_taken';
        })[0].answer_text;
      const appointment_date = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'next_scheduled_appt';
        })[0].answer_text;
      const csq_data = this.state.treatment_data;
      const csq = JSON.stringify(this.state.treatment_data);
      let record_type = 'Progress Record';
      let csr_requested = false;
      let csr_required = this.state.treatment_data.questions.filter(function (csq) {
        return csq.friendly_name === 'csr_required';
      })[0];
      if (csr_required.answer_text === 'Yes') {
        csr_requested = true;
      }
      if (csr_requested) {
        record_type = 'CSR';
      }
      data = {
        case_id,
        csq_data,
        csq,
        date_taken,
        files,
        record_type,
        appointment_date,
        is_bpp: true,
      };
      let that = this;
      this.setState({
        step: 'in_progress',
        buttons: <div />,
      });

      Axios.post(`/apiV2/csr_update/${this.props.progress_record.progress_id}`, data)
        .then(function (res) {
          that.setState({
            step: 'thank_you',
          });
        })
        .catch(function (err) {
          that.setState({
            error: true,
          });
        });
      // this.props.history.push({ state: { refreshInfo: 'true' } });
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'pr_upload' }, { name: 'pr_csq' }, { name: 'pr_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  hasOnePhoto = () => {
    if (this.state.fileUpload.length === 0) {
      return false;
    }
    return true;
  };
  validateUpload = (event) => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '';
    if (this.hasOnePhoto() && !this.state.file_removing && this.state.files_uploading.length === 0) {
      return true;
    } else {
      if (warning) {
        warning_text = '<ul class="wizard-error-text">';
        if (!this.hasOnePhoto()) {
          warning_text = warning_text + '<li>At least 1 file required</li>';
        }

        if (this.state.files_uploading.length > 0) {
          warning_text = warning_text + '<li>File upload in progress</li>';
        }

        if (this.state.file_removing) {
          warning_text = warning_text + '<li>File removal in progress</li>';
        }
        warning_text = warning_text + '</ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Upload Progress Records',
        });
      }
      return false;
    }
  };

  validateTreatmentData = (event) => {
    //Fix: const vs. let
    let treatment_data = this.state.treatment_data;
    let warning_text = '';
    let error = false;
    let none_selected = false;
    let warning = document.querySelector('#warning-submit');
    treatment_data.questions.forEach((csq) => {
      if ((csq.input_type === 'text_area' || csq.input_type === 'text' || csq.input_type === 'date') && !csq.optional && !csq.answer_text) {
        error = true;
        csq.error = true;
      } else if (csq.input_type === 'radio_button') {
        let rb_selected =
          csq.radio_button.filter(function (rb) {
            return rb.radio_checked === true;
          }).length > 0
            ? true
            : false;
        if (!rb_selected) {
          error = true;
          csq.error = true;
        }
        const selected_rb = csq.radio_button.filter(function (rb) {
          return rb.radio_checked === true;
        })[0];
        if (csq.toothSelector === true && csq.answer_text === 'Yes') {
          if (selected_rb.selectedTeeth.length === 0) {
            error = true;
            csq.error = true;
          }
        }
      } else if (csq.input_type === 'multiple_question' && !csq.optional) {
        let rb_none_selected =
          csq.multiple_question.filter(function (m) {
            return m.input_type === 'radio_button' && m.answer_text === 'None';
          }).length > 1
            ? true
            : false;
        if (rb_none_selected) {
          error = true;
          csq.error = true;
          none_selected = true;
        }
        csq.multiple_question.forEach((mq) => {
          if ((mq.input_type === 'text_area' || mq.input_type === 'text' || mq.input_type === 'date') && !mq.optional && !mq.answer_text) {
            if (mq.related_question_id && mq.related_mq_id) {
              if (
                treatment_data.questions
                  .filter(function (q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function (m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
              ) {
                error = true;
                csq.error = true;
                mq.error = true;
              }
            } else {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          } else if (mq.input_type === 'radio_button') {
            let rb_selected =
              mq.radio_button.filter(function (rb) {
                return rb.radio_checked === true;
              }).length > 0
                ? true
                : false;
            if (!rb_selected) {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          }
        });
      }
    });
    if (error) {
      if (warning) {
        if (!warning_text) {
          if (none_selected) {
            warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
          } else {
            warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li></ul>';
          }
        } else {
          warning_text = warning_text + '</ul>';
        }

        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          treatment_data: treatment_data,
          warning: true,
          error_type: 'Clinical Support Questionnaire',
        });
      }
      return false;
    }
    return true;
  };

  validateCsr = (event) => {
    //Fix: const vs. let
    let treatment_data = this.state.csr_data;
    let warning_text = '';
    let error = false;
    let none_selected = false;
    let warning = document.querySelector('#warning-submit');
    treatment_data.questions.forEach((csq) => {
      if ((csq.input_type === 'text_area' || csq.input_type === 'text' || csq.input_type === 'date') && !csq.optional && !csq.answer_text) {
        error = true;
        csq.error = true;
      } else if (csq.input_type === 'radio_button') {
        let rb_selected =
          csq.radio_button.filter(function (rb) {
            return rb.radio_checked === true;
          }).length > 0
            ? true
            : false;
        if (!rb_selected) {
          error = true;
          csq.error = true;
        }
        const selected_rb = csq.radio_button.filter(function (rb) {
          return rb.radio_checked === true;
        })[0];
        if (csq.toothSelector === true && csq.answer_text === 'Yes') {
          if (selected_rb.selectedTeeth.length === 0) {
            error = true;
            csq.error = true;
          }
        }
      } else if (csq.input_type === 'multiple_question' && !csq.optional) {
        let rb_none_selected =
          csq.multiple_question.filter(function (m) {
            return m.input_type === 'radio_button' && m.answer_text === 'None';
          }).length > 1
            ? true
            : false;
        if (rb_none_selected) {
          error = true;
          csq.error = true;
          none_selected = true;
        }
        csq.multiple_question.forEach((mq) => {
          if ((mq.input_type === 'text_area' || mq.input_type === 'text' || mq.input_type === 'date') && !mq.answer_text) {
            if (mq.related_question_id && mq.related_mq_id) {
              if (
                treatment_data.questions
                  .filter(function (q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function (m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
              ) {
                error = true;
                csq.error = true;
                mq.error = true;
              }
            } else {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          } else if (mq.input_type === 'radio_button') {
            let rb_selected =
              mq.radio_button.filter(function (rb) {
                return rb.radio_checked === true;
              }).length > 0
                ? true
                : false;
            if (!rb_selected) {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          }
        });
      }
    });
    if (error) {
      if (warning) {
        if (!warning_text) {
          if (none_selected) {
            warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
          } else {
            warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li></ul>';
          }
        } else {
          warning_text = warning_text + '</ul>';
        }

        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          csr_data: treatment_data,
          warning: true,
          error_type: 'Clinical Support Questionnaire',
        });
      }
      return false;
    }
    return true;
  };

  onhandleTeethClick(rb, id_string) {
    let treatment_data = this.state.csr_data;
    const selected_teeth = rb.selectedTeeth;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].selected_teeth = selected_teeth;
    } else {
      if (
        treatment_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].radio_button
      ) {
        treatment_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .radio_button.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      } else if (
        treatment_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].check_box
      ) {
        treatment_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .check_box.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      }
    }
    this.setState({
      csr_data: treatment_data,
    });
    this.hide_warning();
  }

  onButtonClick = (event) => {
    event.preventDefault();
    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    if (this.state.step === 'pr_csq') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.setState(
          {
            step: 'pr_upload',
            buttons: (
              <div>
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      } else if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateTreatmentData()) {
          return;
        } else {
          this.hide_warning();
        }
        this.isProgressRecordDeleted();
        this.setState(
          {
            step: 'pr_review',
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      }
    } else if (this.state.step === 'pr_upload') {
      if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateUpload() || this.state.file_removing || this.state.files_uploading.length > 0) {
          return;
        } else {
          this.hide_warning();
        }

        this.setState(
          {
            step: 'pr_csq',
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      }
    } else if (this.state.step === 'pr_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.hide_warning();
        this.setState(
          {
            step: 'pr_csq',
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      }
    }
  };

  hide_warning = () => {
    let treatment_data = this.state.treatment_data;
    treatment_data.questions.forEach((csq) => {
      csq.error = false;
      if (csq.input_type === 'multiple_question') {
        csq.multiple_question.forEach((mq) => {
          mq.error = false;
        });
      }
    });

    this.setState({
      treatment_data: treatment_data,
      warning: false,
    });
  };

  goBack() {
    this.props.ReturnBack();
  }

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons[id].booleanValue;
    request_reasons[id].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onCsqTextFocus = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let treatment_data = this.state.treatment_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = removeEmoji(textFieldLimited(event.target.value.trim()));
    } else {
      treatment_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = removeEmoji(textFieldLimited(event.target.value.trim()));
    }

    this.setState({
      treatment_data: treatment_data,
    });
    this.hide_warning();
  };

  onStepEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  onRemoveDraft = (event) => {
    this.goBack();
  };

  onCsqCbChange = (event) => {
    let treatment_data = this.state.treatment_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked
    ) {
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = false;
    } else {
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = true;
    }

    this.setState({
      treatment_data: treatment_data,
    });
    this.hide_warning();
  };

  onCsqRbChange = (event) => {
    let treatment_data = this.state.treatment_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    } else {
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      treatment_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    }
    let csr_required = treatment_data.questions.filter(function (csq) {
      return csq.friendly_name === 'csr_required';
    })[0];
    let csr_data = this.state.csr_data;
    let csr_requested = false;
    if (csr_required && id === csr_required.id) {
      if (csr_required.answer_text === 'Yes') {
        csr_requested = true;
        treatment_data.questions.push.apply(treatment_data.questions, this.state.csr_data.questions);
      } else {
        let csr_questions = treatment_data.questions.filter(function (csq) {
          return csq.id >= 1000;
        });
        let treatment_questions = treatment_data.questions.filter(function (csq) {
          return csq.id < 1000;
        });
        treatment_data.questions = treatment_questions;
        if (csr_questions.length > 0) {
          csr_data.questions = csr_questions;
        }
      }
    }
    this.setState({
      treatment_data: treatment_data,
      csr_data: csr_data,
      csr_requested: csr_requested,
    });
    this.hide_warning();
  };

  onUploadTextChange = (event) => {
    const upload_date = removeEmoji(textFieldLimited(event.target.value));
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onUploadTextBlur = (event) => {
    const upload_date = removeEmoji(textFieldLimited(event.target.value.trim()));
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onCsqTextChange = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let rb_id = 0;
    let treatment_data = this.state.treatment_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 4) {
      rb_id = parseInt(id_array[2]);
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].text_area.answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 5) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
      treatment_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].date.answer_text = textFieldLimited(event.target.value);
    } else {
      treatment_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = textFieldLimited(event.target.value);
    }

    this.setState({
      treatment_data: treatment_data,
    });
    this.hide_warning();
  };

  files_upload = (filenames) => {
    this.setState({
      files_uploading: filenames,
    });
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    for (let index = 0; index < data.length; index++) {
      if (!data[index]['file_url']) {
        const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
        data[index]['file_url'] = file_url;
      }
    }
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    this.setState({
      warning: false,
    });

    if (data && data[0].folder) {
      let uploadData = [];
      uploadData = this.state.fileUpload;
      uploadData = uploadData.concat(data);

      this.setState({
        fileUpload: uploadData,
        files_uploading: [],
      });
    }
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');
    let that = this;

    if (startingPath >= 0 && endPath >= 0) {
      let files = decodeURI(href.substring(startingPath, href.length));

      const data = {
        files,
      };

      that.setState({
        file_removing: true,
      });

      Axios.post(`/apiV2/pr_remove_upload`, data)
        .then(function (res) {
          //Update state that removed incomplete file
          //

          if (res.data) {
            let uploadData = that.state.fileUpload;
            let newFileUpload = [];

            for (let i = 0; i < uploadData.length; i++) {
              if (uploadData[i].upload_data !== files.substring(1)) {
                newFileUpload.push(uploadData[i]);
              }
            }

            that.setState({
              fileUpload: newFileUpload,
              file_removing: false,
            });
          }
        })
        .catch(function (err) {
          let uploadData = that.state.fileUpload;
          let newFileUpload = [];

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].upload_data !== files.substring(1)) {
              newFileUpload.push(uploadData[i]);
            }
          }
          that.setState({
            fileUpload: newFileUpload,
            remove_confirm: false,
            file_removing: false,
          });
        });
    }
  };

  onEditClick = (event) => {
    this.setState({
      edit_comment: true,
      edit_status_comment: this.state.status_comment,
    });
  };
  onEditStatusDiscard = (event) => {
    this.setState({
      edit_comment: false,
    });
  };

  onEditStatusConfirm = (event) => {
    if (this.state.edit_status_comment) {
      this.setState({
        edit_comment: false,
        status_comment: this.state.edit_status_comment.trim(),
      });
    }
    //this.props.confirmStatuUpdate(this.state.edit_status_comment);
  };

  onTextChange = (event) => {
    this.setState({
      edit_status_comment: event.target.value,
    });
  };

  onBlur = (event) => {
    this.setState({
      edit_status_comment: event.target.value.trim(),
    });
  };
  onEditStatusKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    }
  }

  setButtons(action) {
    if (action === 'pr_upload') {
      this.setState({
        buttons: (
          <div>
            <NextButton position={'next'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (action === 'pr_csq') {
      this.setState({
        buttons: (
          <div>
            <BackButton position={'prev'} onButtonClick={this.onButtonClick} />
            <NextButton position={'next'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (action === 'pr_review') {
      this.setState({
        buttons: (
          <div>
            <BackButton position={'prev'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="page-tab-content">
          <Loader windowSize="ir" />
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else {
      return (
        <div className="page-tab-content bpp-ir-submission csr-submission">
          <Helmet>
            <title>{this.state.title} Submission | InBrace Smile Design Studio™</title>
          </Helmet>

          {/* <div className="page__heading page__heading--long">
            <div className="page__name page__name--default">
              {' '}
              {this.state.title} - {this.state.patient_first_name} {this.state.patient_last_name}
            </div>
          </div>
           */}
          <div className="row">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="pull-right" />
            </div>
          </div>
          <div className="">
            <div className="">
              {this.state.step === 'pr_upload' ? (
                <Upload
                  id={this.props.id}
                  goBack={this.onRemoveDraft}
                  photoUpload={this.state.fileUpload}
                  onEmptyFileError={this.onEmptyFileError}
                  onUpload={this.onUpload}
                  onRemove={this.onRemove}
                  upload_state={this.files_upload}
                  upload_content={this.state.files_uploading}
                  show_warning={this.show_warning}
                  hide_warning={this.hide_warning}
                  location={this.state.file_location}
                  upload_date={this.state.upload_date}
                  onTextChange={this.onUploadTextChange}
                  onBlur={this.onUploadTextBlur}
                  error={this.state.warning}
                  mode="update"
                />
              ) : null}
              {this.state.step === 'pr_csq' ? (
                <TreatmentDetails
                  treatment_data={this.state.treatment_data}
                  onCheckboxClicked={this.onCheckboxClicked}
                  onTextChange={this.onCsqTextChange}
                  onRbChange={this.onCsqRbChange}
                  onCbChange={this.onCsqCbChange}
                  onFocus={this.onCsqTextFocus}
                  error={this.state.warning}
                  onhandleTeethClick={(rb, id_string) => {
                    this.onhandleTeethClick(rb, id_string);
                  }}
                />
              ) : null}
              {this.state.step === 'thank_you' ? (
                <div className="loader-container-2">
                  <div className="bpp-loader-header">
                    <p className="text-normal">
                      You have successfully updated this {this.props.type === 'CSR' ? 'Progress Record and Clinical Support Request' : 'Progress Record'}
                    </p>
                    <button className="btn btn-light" onClick={this.redirectUserToCaseDetail}>
                      Return to Details
                    </button>
                  </div>
                </div>
              ) : null}

              {this.state.step === 'in_progress' ? <SubmitterLoader /> : null}
              {this.state.step === 'pr_review' ? (
                <div className="bpp-portal-view">
                  <div className="ir-submission-heading">
                    <h4>Submit Progress Records - Step 3 of 3</h4>
                  </div>
                  <div>
                    <div className="extra-padding-headline" />
                  </div>
                  <div>
                    <span className="bpp-pr-upload-date-label">Click "Update" to complete the update process</span>
                    <div className="extra-padding-headline" />
                  </div>

                  <ThumbnailUrlInterface
                    files={this.state.fileUpload}
                    images={configLightBox(this.state.fileUpload)}
                    multinail={`_${this.state.case_id}`}
                    hide_occlusion={true}
                  />

                  <div className="review__line-divison" />
                  {this.props.mode === 'update' && this.state.status_comment ? (
                    <div>
                      <div className="bpp-review__line-divison" />
                      <div className="review-headers review-heading bold-text">
                        <h4>Reason for Update:</h4>
                      </div>
                      <div className="bpp-heading-edit-button" data-action="update_reason" onClick={this.onEditClick}>
                        Edit
                      </div>
                      {this.state.edit_comment ? (
                        <div>
                          <textarea
                            className={!this.state.edit_status_comment ? 'form-control bpp-pr-reason-comment-warning' : 'form-control bpp-item-update-comment'}
                            data-id="6"
                            rows="2"
                            value={this.state.edit_status_comment}
                            onChange={this.onTextChange}
                            onBlur={this.onBlur}
                            onKeyDown={this.onEditStatusKeyPress}
                          />
                          <div className="ir-profile-edit">
                            <div
                              id="edit-confirm"
                              className="btn btn-light btn-next"
                              onClick={this.onEditStatusConfirm}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Save"
                            >
                              <i className="fa fa-check" aria-hidden="true" />
                            </div>
                            <div
                              id="edit-discard"
                              className="btn btn-light btn-next"
                              onClick={this.onEditStatusDiscard}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Cancel"
                            >
                              <i className="fa fa-times" aria-hidden="true" />
                            </div>
                            {this.state.name_error ? <span className="name-warning-text">Please enter valid comment</span> : null}
                          </div>
                        </div>
                      ) : (
                        <div>{this.state.status_comment}</div>
                      )}
                    </div>
                  ) : null}
                  <div>
                    <div className="review-headers review-heading">
                      <h4>Treatment Details:</h4>
                    </div>
                    <div className="bpp-heading-edit-button" data-action="pr_csq" onClick={this.onStepEditClick}>
                      Edit
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <div className="bold-text col-sm-12 csr-question">I Am Submitting...</div>
                      <div className="col-sm-12 csr-answer">
                        {
                          this.state.treatment_data.questions.filter(function (q) {
                            return q.friendly_name === 'submission_type';
                          })[0].answer_text
                        }
                      </div>
                      <div className="bold-text col-sm-12 csr-question">Treatment Notes</div>
                      <div className="col-sm-12 csr-answer">
                        {this.state.treatment_data.questions.filter(function (q) {
                          return q.friendly_name === 'treatment_notes';
                        })[0].answer_text
                          ? this.state.treatment_data.questions.filter(function (q) {
                              return q.friendly_name === 'treatment_notes';
                            })[0].answer_text
                          : 'N/A'}
                      </div>
                      <div className="question-size">
                        <div className="bold-text col-sm-6 csr-question">Date Progress Records Were Taken</div>
                        <div className="bold-text col-sm-6 csr-question">Date of Next Scheduled Appointment</div>
                        <div className="col-sm-6 csr-answer">
                          {this.state.treatment_data.questions
                            .filter(function (q) {
                              return q.friendly_name === 'pr_dates';
                            })[0]
                            .multiple_question.filter(function (q) {
                              return q.friendly_name === 'pr_date_taken';
                            })[0].answer_text
                            ? this.state.treatment_data.questions
                                .filter(function (q) {
                                  return q.friendly_name === 'pr_dates';
                                })[0]
                                .multiple_question.filter(function (q) {
                                  return q.friendly_name === 'pr_date_taken';
                                })[0].answer_text
                            : 'N/A'}
                        </div>
                        <div className="col-sm-6 csr-answer">
                          {this.state.treatment_data.questions
                            .filter(function (q) {
                              return q.friendly_name === 'pr_dates';
                            })[0]
                            .multiple_question.filter(function (q) {
                              return q.friendly_name === 'next_scheduled_appt';
                            })[0].answer_text
                            ? this.state.treatment_data.questions
                                .filter(function (q) {
                                  return q.friendly_name === 'pr_dates';
                                })[0]
                                .multiple_question.filter(function (q) {
                                  return q.friendly_name === 'next_scheduled_appt';
                                })[0].answer_text
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="question-size">
                        <div className="bold-text col-sm-6 csr-question">Current Upper Smartwire</div>
                        <div className="bold-text col-sm-6 csr-question">Current Lower Smartwire</div>
                        <div className="col-sm-6 csr-answer">
                          {
                            this.state.treatment_data.questions
                              .filter(function (q) {
                                return q.friendly_name === 'smartwire_type';
                              })[0]
                              .multiple_question.filter(function (q) {
                                return q.friendly_name === 'upper_smartwire_type';
                              })[0].answer_text
                          }
                        </div>

                        <div className="col-sm-6 csr-answer">
                          {
                            this.state.treatment_data.questions
                              .filter(function (q) {
                                return q.friendly_name === 'smartwire_type';
                              })[0]
                              .multiple_question.filter(function (q) {
                                return q.friendly_name === 'lower_smartwire_type';
                              })[0].answer_text
                          }
                        </div>
                      </div>
                      <div className="bold-text col-sm-12 csr-question">Clinical Support Requested</div>
                      <div className="col-sm-12 csr-answer">
                        {
                          this.state.treatment_data.questions.filter(function (q) {
                            return q.friendly_name === 'csr_required';
                          })[0].answer_text
                        }
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-light btn-bpp-pr"
                    type="button"
                    onClick={this.onSubmitButtonClick}
                    data-case_id={this.state.case_id}
                    disabled={this.state.edit_comment}
                  >
                    Update
                  </button>
                </div>
              ) : null}
            </div>
            {this.state.hide ? null : this.state.buttons}

            <div id="wizard-warning" className={this.state.warning ? 'wizard-error noselect' : 'wizard-error wizard-hidden'}>
              <div className="wizard-error-title">
                <span id="wizard-heading" className="wizard-error-title-main">
                  {this.state.error_type}
                </span>{' '}
                <i className="fa fa-times pull-right pointer" aria-hidden="true" onClick={this.removeWizardErrorMsg} />
              </div>
              <div id="warning-submit">Warning</div>
            </div>
            {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
              <div>
                <span className="bpp-ir-cancel-text bpp-ir-cancel-text-icon" onClick={this.onModalShow}>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
                <span className="bpp-ir-cancel-text" onClick={this.onModalShow}>
                  Cancel
                </span>
              </div>
            ) : null}

            {this.state.showCancelCase === true ? (
              <Modal
                preset="decision"
                header_text="Cancel"
                message_text={
                  <span>
                    Are you sure you want to cancel this submission?
                    <br />
                    You will not be able to undo this action if you proceed.
                  </span>
                }
                close_btn_text="Do Not Cancel"
                confirm_btn_text="Cancel Submission"
                onCloseButtonClick={this.onModalDismiss}
                onConfirmButtonClick={this.onRemoveDraft}
                theme="bpp"
              />
            ) : null}
            {this.state.showEmptyFileModal ? <EmptyFileErrorModal theme="bpp" onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} /> : null}
          </div>
        </div>
      );
    }
  }
}
export default withRouter(CsrSubmission);
